<template>
  <div class="getCoupons">
    <nav-bar
      :isFixed="true"
      :title="$t('領券中心')"
      :bgc="'background-color: #90d12e;'"
      v-if="!isMobileTerminal()"
    ></nav-bar>
    <div
      class="money-content"
      v-if="canGetDisCoupons.length>0"
    >
          <div class="noquantity-content">
            <div class="limit-content" v-for="item in canGetDisCoupons" :key="item.id">
              <div class="noquantity-received-img">
                <img
                  v-if="item.isGet && langEnv == 3"
                  src="@/assets/image/coupon/Usedzh.png"
                  alt=""
                />
                <img
                  v-if="item.isGet && langEnv != 3"
                  src="@/assets/image/coupon/Receive.png"
                  alt=""
                />
              </div>
              <div class="limit-left">
                <div class="limit-price">
                  <div class="price">
                    {{ "" + item.discount }}<span>%OFF</span>
                  </div>
                </div>
                <div
                class="max-conditions"
                v-if="langEnv == '3'"
               
              >
                {{ $t("滿") }} {{ item.metPrice }} {{ $t("可用") }}
              </div>
              <div class="max-conditions" v-else >
                {{ $t("可用") }} {{ $t("滿") }} {{ item.metPrice }}
              </div>
              </div>
              <div class="limit-right">
                <div>
                  <div class="title">
                    <div class="left">
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="left">{{ item.date }}</div>
                    <div
                      class="receive-btn"
                      @click="nowOrder(item.id, 0, item.type)"
                      v-if="!item.isGet"
                    >
                      {{ $t("領取") }}
                    </div>
                  </div>
                  <div class="question" v-if="item?.isAll">
                    <div v-if="item?.isAll">{{ $t("適用於所有的產品") }}</div>
                  </div>
                  <div class="maxDiscountPrice" v-if="item.maxDiscountPrice">
                    <div v-if="langEnv == 3">
                      {{ $t("最高可减") }}{{ item.maxDiscountPrice }}
                    </div>
                    <div v-else>
                      {{ $t("最高") }}{{ item.maxDiscountPrice }}{{ $t("可减") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

    <div
      class="money-content"
      v-if="canGetCoupons.length > 0"
    >
      <div class="noquantity-content">
        <div
          class="limit-content"
          v-for="item in canGetCoupons"
          :key="item.id"
        >
          <div class="noquantity-received-img" v-if="item.isGet">
            <img
              v-if="item.isGet && langEnv == 3"
              src="@/assets/image/coupon/Usedzh.png"
              alt=""
            />
            <img
              v-if="item.isGet && langEnv != 3"
              src="@/assets/image/coupon/Receive.png"
              alt=""
            />
          </div>
          <!-- <img src="@/assets/image/coupon/money_bg.png" alt="" /> -->
          <div class="limit-left">
            <div class="limit-price">
              <span>HK$</span>
              <div class="price">{{ item.price }}</div>
            </div>
            <div class="max-conditions">
              <div
                v-if="langEnv == '3'"
               
              >
                {{ $t("滿") }} {{ item.metPrice }} {{ $t("可用") }}
              </div>
              <div v-else >
                {{ $t("可用") }} {{ $t("滿") }} {{ item.metPrice }}
              </div>
            </div>
          </div>
          <div class="limit-right">
            <div>
              <div class="title">
                <div class="left">
                  <!-- <div class="left-content">
                    {{ item.isAll ? "全场券" : "品类券" }}
                  </div> -->
                  {{ item.name }}
                </div>
              </div>
              <div class="bottom">
                <div class="left">{{ item.date }}</div>
                <div
                  class="right"
                  v-if="!item.isGet"
                  @click="nowOrder(item.id, 1, '0')"
                >
                  {{ $t("領取") }}
                </div>
              </div>
              <div class="question" v-if="item.isAll">
                <div v-if="item.isAll">{{ $t("適用於所有的產品") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Mixins from "@/utils/mixins.js";
import { getFindCanGetCoupon, getTakeCanGetCoupon } from "@/api/coupon.js";
import { getToken } from "@/utils/token.js";
import i18n from "@/i18n";
export default {
  directives: {
    "mouse-drag-scroll": {
      bind(el) {
        let isDragging = false;
        let startX, scrollLeft;

        el.addEventListener("mousedown", e => {
          isDragging = true;
          startX = e.pageX - el.offsetLeft;
          scrollLeft = el.scrollLeft;
          el.style.cursor = "grabbing";
        });

        el.addEventListener("mouseleave", () => {
          isDragging = false;
          el.style.cursor = "grab";
        });

        el.addEventListener("mouseup", () => {
          isDragging = false;
          el.style.cursor = "grab";
        });

        el.addEventListener("mousemove", e => {
          if (!isDragging) return;
          e.preventDefault();
          const x = e.pageX - el.offsetLeft;
          const walk = (x - startX) * 1.5;
          el.scrollLeft = scrollLeft - walk;
        });
      }
    }
  },
  data() {
    return {
      countdownValue: [],
      canGetDisCoupons: [],
      canGetCoupons: [],
      flag1: false,
      flag2: true,
      timer: null,
      CanGetCoupon: {},
      statuCoupon: null,
      langEnv:'3'
    };
  },
  // watch: {
  //   CanGetCoupon: {
  //     deep: true,
  //     handler(val) {
  //       const countdown = (receiveEndTime, index) => {
  //         const now = new Date();
  //         const timeDiff = receiveEndTime - now.getTime();
  //         if (timeDiff <= 0) {
  //           this.findCanGetCoupon();
  //           return;
  //         }

  //         const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  //         const hours = Math.floor(
  //           (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //         );
  //         const minutes = Math.floor(
  //           (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
  //         );
  //         const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  //         // 格式化字符串，补零
  //         const formattedDays = days.toString().padStart(2, "0");
  //         const formattedHours = hours.toString().padStart(2, "0");
  //         const formattedMinutes = minutes.toString().padStart(2, "0");
  //         const formattedSeconds = seconds.toString().padStart(2, "0");
  //         return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
  //       };
  //       val.canGetDisCoupons.forEach((item, index) => {
  //         this.countdownValue.splice(
  //           index,
  //           1,
  //           countdown(item.receiveEndTime, index)
  //         );
  //       });
  //       clearInterval(this.timer);
  //       this.timer = setInterval(() => {
  //         val.canGetDisCoupons.forEach((item, index) => {
  //           this.countdownValue.splice(
  //             index,
  //             1,
  //             countdown(item.receiveEndTime, index)
  //           );
  //         });
  //       }, 1000);
  //     }
  //   }
  // },
  mixins: [Mixins],
  created() {
    this.langEnv = window.localStorage.getItem("langEnv") || 2
    this.findCanGetCoupon();

    document.title = this.$t("領券中心");
  },
  methods: {
    async findCanGetCoupon() {
      const res = await getFindCanGetCoupon({}, this.langEnv);
      this.canGetCoupons = res.data.canGetCoupons;
      this.canGetDisCoupons = res.data.canGetDisCoupons.map(item=>item.coupons).flat(1).map(it => it)
      
      // res.data.canGetDisCoupons = res.data.canGetDisCoupons.map(item => {
      //   if (item.type === "1") {
      //     const dateStr = item.receiveStartTime;
      //     const dateObj = new Date(dateStr);
      //     item.month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      //     item.day = dateObj
      //       .getDate()
      //       .toString()
      //       .padStart(2, "0");
      //     item.hours = dateObj
      //       .getHours()
      //       .toString()
      //       .padStart(2, "0");
      //     item.minutes = dateObj
      //       .getMinutes()
      //       .toString()
      //       .padStart(2, "0");
      //     item.seconds = dateObj
      //       .getSeconds()
      //       .toString()
      //       .padStart(2, "0");
      //   }
      //   return item;
      // });
    
      // commit(Types.GET_CAN_COUPONS, res.data);
    },
    async nowOrder(id, flag, type) {
      // this.isLogin();
      // if (type !== "0") {
      //   this.$toast("活动未开始，请稍后再来");
      //   return;
      // }
      if (this.isLogin()) {
        const res = await getTakeCanGetCoupon({ id: id }, this.langEnv);

        await this.findCanGetCoupon();
        if (res.isSuccess) {
          this.statuCoupon = 1;

          // commit(Types.GET_COUS2, data);
          this.$toast(this.$t("領取") + this.$t("成功"));
        }
      }
    },
    nowUse(isAll, id, name) {
      if (this.isLogin()) {
        var _this = this;
        var func1 = function() {
          if (name.indexOf("义齿") > -1) {
            _this.gotoTooth();
            return;
          }
          mmm.immediatelyUse(!isAll ? id : "");
        };
        var func2 = function() {
          if (name.indexOf("义齿") > -1) {
            _this.gotoTooth();
            return;
          }
          window.webkit.messageHandlers.immediatelyUse.postMessage(
            !isAll ? id : ""
          );
        };
        var func3 = function() {
          if (name.indexOf("义齿") > -1) {
            _this.gotoTooth();
            return;
          }
          if (!isAll) {
            _this.$router.push({
              path: "/products",
              query: { useCoupon: 1, couponId: id }
            });
          } else {
            _this.$router.push({ path: "/products", query: { useCoupon: 1 } });
          }
        };
        this.excFunc(func1, func2, func3);
      }
    },
    gotoTooth() {
      var _this = this;
      Request.fetchByAuth2(
        "tooth/sure-auth",
        null,
        res => {
          if (res.resultID === 1340) {
            var func1 = function() {
              mmm.goAuth();
            };
            var func2 = function() {
              window.webkit.messageHandlers.goAuth.postMessage("a");
            };
            var func3 = function() {
              _this.showMsg("您未认证，请先下载梅苗苗APP认证");
            };
            _this.excFunc1(func1, func2, func3);
          } else {
            _this.$router.push({ path: "/serviceNotice" });
          }
        },
        "POST",
        false
      );
    },
    getDay(item) {
      let start = item.date.split("-")[0].replace(/\./g, "-");
      return this.dateMinus(
        new Date(this.dateFtt("yyyy-MM-dd", new Date())),
        new Date(start)
      );
    },
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    dateMinus(date1, date2) {
      //date1:小日期   date2:大日期
      var sdate = new Date(date1);
      var now = new Date(date2);
      var days = now.getTime() - sdate.getTime();
      var day = parseInt(days / (1000 * 60 * 60 * 24));
      return day;
    },
    isLogin() {
      var token = getToken();
      if (!token) {
        var _this = this;
        var func1 = function() {
          mmm.toLogin();
        };
        var func2 = function() {
          window.webkit.messageHandlers.toLogin.postMessage("jj");
        };
        var func3 = function() {
          _this.$router.push({
            path: "/login?redirect=" + _this.$route.fullPath
          });
        };

        this.excFunc(func1, func2, func3);
      } else {
        return true;
      }
    }
  }

  // unmounted() {
  //   $("#navbar").css({ background: "#f9f9f9" });
  // },
};
</script>
<style scoped lang="scss">
#navbar {
  background-color: #90d12e;
}
.getCoupons {
  background-color: #90d12e;
  padding: 12px;
  padding-top:44px;
  min-height: 100vh;
  max-width: 640px;
  min-width: 320px;
  overflow: hidden;
  margin: 0 auto;
}

.money-content {
  width: 100%;
  margin-top: 12px;
}
.money-content .title {
  width: 100%;
}
.money-content .title img {
  width: 100%;
}
.block-box {
  width: 18px;
  height: 18px;
  background: #928df9;
  color: #fff;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  margin: 0 2px;
  border-radius: 2px;
  font-weight: 400;
}
.h {
  margin-left: 4px;
}

.coupons-content {
  display: flex;
  align-items: center;
  padding: 0 12px;
  .right {
    display: flex;
  }
}
.first-coupon {
  margin-left: 16px;
}
.last-coupon {
  margin-right: 16px !important;
}
.coupons-title {
  display: flex;
}
.coupons .coupons-num {
  width: 52px;
  height: 22px;
  font-size: 12px;
  color: #e84935;
  background: linear-gradient(270deg, #fac9c4 0%, #fddad6 100%);
  border-radius: 0 0 8px 0;
  text-align: center;
  line-height: 22px;
  visibility: hidden;
}
.coupons .decoration img {
  width: 12px;
  left: 52px;
}
.coupons-content .discount {
  color: #e84935;
  font-size: 22px;
  margin-top: 8px;
  font-weight: 700;
}

.coupons-content .condition {
  color: #e84935;
  font-size: 12px;
}
.discount-coupon-name {
  font-size: 13px;
  width: 102%;
  margin: 7px 0 2px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}
.period-validity {
  font-size: 16px;
  transform: scale(0.625);
  color: #666666;
  width: 150%;
  margin-left: 12px;
  margin-top: -3px;
}
.receive-btn {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(270deg, #e84935 0%, rgba(232, 73, 53, 0.6) 100%);
  width: 6.3vh;
  font-size: 12px;
  border-radius: 2.4vh;
  text-align: center;
  padding: 2px 8px;
}
.maximum-discount {
  font-size: 16px;
  transform: scale(0.625);
  color: #ef7b6c;
}
.received {
  background: linear-gradient(270deg, #e84935 0%, rgba(232, 73, 53, 0.6) 100%)
    rgba(255, 255, 255, 0.6) !important;
  opacity: 0.4;
}
.received-img img {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
}
.noquantity-content {
  padding: 16px;
  padding-top: 32px;
  border-radius: 16px;
  background-color: #fff;
}
.noquantity-content .limit-content {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 92px;
  position: relative;
  background: url("~@/assets/image/coupon/mycoupon.png") no-repeat;
  background-size: 115% 100%;
  border-radius: 12px;
}
.noquantity-content .limit-content img {
  width: 100%;
}
.limit-left {
  width: 34%;
  padding: 0 5px;
  box-sizing: border-box;
}
.limit-left .limit-price {
  font-size: 18px;
  color: #e84935;
  font-weight: 800;
  display: flex;
  align-items:baseline;
  justify-content: center;
  line-height: 1;
  margin-bottom: 4px;
  span {
    font-size: 12px;
  }
  .price {
    font-size: 18px;
    span {
      font-size: 12px;
    }
    // .unit{
    //   font-size: 12px;
    // }
  }
}
.limit-left .max-conditions {
  color: #e84935;
  font-size: 12px;
  text-align: center;
  div{
    text-align: center;
  }
}
.limit-right {
  padding: 12px;
  flex: 1;
  font-size: 2vh;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}
.limit-right .title {
  overflow: hidden;
  min-height: 40px;
}
.limit-right .bottom {
  display: flex;
  align-items: center;
  font-weight: 400;
  bottom: 0.6vh;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.limit-right .title .left-content {
  font-size: 1.6vh;
  height: 2.4vh;
  line-height: 2.4vh;
  width: 6.1vh;
  text-align: center;
  border-radius: 0.6vh 0.6vh 0.6vh 0;
  font-weight: 400;
  margin-right: 0.6vh;
  background: #e84935;
  color: #fff;
  margin-top: 0.6vh;
  margin: 0;
  display: inline-block;
}

.limit-content {
  position: relative;
  height: 100%;
  margin-bottom: 16px;
}
.noquantity-received-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 8.4vh;
  height: 8.4vh;
}
.noquantity-received-img img {
  width: 100%;
}
.limit-right .bottom .left {
  font-size: 10px;
  color: #666666;
  flex: 1;
}
.limit-right .bottom .right {
  cursor: pointer;
  font-size: 2vh;
  color: #fff;
  background: linear-gradient(270deg, #e84935 0%, rgba(232, 73, 53, 0.6) 100%);
  width: 6.3vh;
  font-size: 12px;
  border-radius: 2.4vh;
  text-align: center;
  padding: 2px 8px;
}
.no-inventory {
  background: linear-gradient(270deg, #bdbdbd 0%, #e0d3d3 100%) !important;
}

.maxDiscountPrice {
  font-weight: 500;
  font-size: 10px;
  color: #ef7b6c;
}
.question {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #999999;
  font-weight: normal;
}
</style>
